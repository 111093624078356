/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***
@import "utils/variables";

:root {
    --theme-deafult: #95d12d;
    --theme-secondary: #006450;
    --theme-tertiary: #f7f3eb;
    --theme-deafult-rgb: 149, 209, 45;
    --theme-secondary-rgb: 0, 100, 80;
    --theme-tertiary-rgb: 247, 243, 235;
    --theme-deafult-darker: #006450;
}

// *** base ***
@import "base/reset";
@import "base/typography";


//  *** components ***
@import "components/according.scss";
@import "components/alert.scss";
@import "components/avatars.scss";
@import "components/badge.scss";
@import "components/bookmark.scss";
@import "components/breadcrumb.scss";
@import "components/builders.scss";

@import "components/buttons.scss";

@import "components/card.scss";
@import "components/datatable.scss";
@import "components/datepicker.scss";
@import "components/dropdown.scss";
@import "components/form-builder.scss";
@import "components/form-input.scss";
@import "components/form-wizard";
@import "components/forms.scss";
@import "components/form_builder-2.scss";
@import "components/icons.scss";
@import "components/list.scss";
@import "components/loader.scss";
@import "components/popover.scss";
@import "components/print.scss";
@import "components/radio.scss";
@import "components/ribbon.scss";
@import "components/switch.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/touchspin.scss";
@import "components/tour.scss";
@import "components/tree.scss";
@import "components/typeahead-search.scss";
@import "components/scrollbar";



//	*** pages ***

@import "pages/blog.scss";
@import "pages/bookmark-app.scss";
@import "pages/cart.scss";
@import "pages/chart.scss";
@import "pages/chat.scss";
@import "pages/checkout.scss";
@import "pages/comingsoon.scss";
@import "pages/contacts.scss";
@import "pages/dashboard_2.scss";
@import "pages/dashboard_default.scss";
@import "pages/ecommerce.scss";
@import "pages/email-application.scss";
@import "pages/errorpage.scss";
@import "pages/faq.scss";
@import "pages/file.scss";
@import "pages/gallery.scss";
@import "pages/internationalization.scss";
@import "pages/job-search.scss";
@import "pages/jsgrid.scss";
@import "pages/kanban.scss";
@import "pages/knowledgebase.scss";
@import "pages/landing.scss";
@import "pages/language.scss";
@import "pages/learning.scss";
@import "pages/login.scss";
@import "pages/megaoption.scss";
@import "pages/order-history.scss";
@import "pages/page.scss";
@import "pages/pricing.scss";
@import "pages/progress.scss";
@import "pages/projectlist.scss";
@import "pages/social-app.scss";
@import "pages/task.scss";
@import "pages/timeline-v.scss";
@import "pages/timeliny.scss";
@import "pages/user-profile.scss";
@import "pages/wishlist.scss";


/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark.scss";
@import "themes/dark-sidebar.scss";
@import "themes/theme-customizer.scss";
@import "themes/update.scss";
// *** layout ***

@import "layout/footer.scss";
@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/search.scss";
@import "layout/select2.scss";
@import "layout/sidebar.scss";
@import "layout/rtl.scss";
@import "layout/box-layout.scss";
@import "custom.scss";
